import React, { useState, useEffect } from "react"
import { Form, Button, Col, Row } from "react-bootstrap"
import { countryList } from "./countryList"
import locales from "../translate/locales"
import { Formik } from "formik"
import { Modal } from "react-bootstrap"

import * as Yup from "yup"
import { enrollFormsCollectionRef } from "../config/collectionsRef"
import { message } from "antd"

interface Props {
  showModal: boolean
  setShowModal: any
  courseId: string
  courseName: string
  courseLocation: string
  lang: "ar" | "en"
}

export const EnrollCourseForm = (props: Props) => {
  const {
    courseId,
    setShowModal,
    showModal,
    lang,
    courseName,
    courseLocation,
  } = props
  const [completeForm_1, setCompleteForm_1] = useState(false)
  const [completeForm_2, setCompleteForm_2] = useState(false)

  const locale = lang
  const texts = locales[locale as "en"]

  const translate = texts.enrollCourse

  const schema = Yup.object({
    name: Yup.string().min(2).required("Pleas Enter Your Name"),
    lastName: Yup.string().min(2).required(),
    whereYouWork: Yup.string().min(2).required("Pleas Enter Where You Work"),
    email: Yup.string().email("invalid Email").required(),
    number: Yup.number().min(8).required(),
    landlineNumber: Yup.number(),
  })

  function firebaseSubmit(values: any) {

    enrollFormsCollectionRef
      .add({
        submittedDate: new Date(),
        courseLocation: courseLocation,
        course: courseName,
        ...values,
      })
      .then(() => {
        setShowModal(false)
        alert(`Hi Mr.${values.name + "" + values.lastName} Your Enroll Success`)
      })
  }

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      animation
      scrollable
      style={{
        maxHeight: 1000,
        overflowY: "scroll",
        minWidth: 900,
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#021a38" }}>
          {translate.EnrollForm}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => firebaseSubmit(values)}
          initialValues={{
            name: "",
            lastName: "",
            whereYouWork: "",
            email: "",
            number: "",
            landlineNumber: "",
            tdNameOfPerson: "",
            tdEmail: "",
            trainingAdministrationNo: "",
            theOfficialMobileNumber: 0,
            newChooseAnotherDate: null,
            newSuggestAnewNumberOfDays: 0,
            newCity: "",
            newCourseThemes: "",
            joinOurNewsletter: false,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="formGroupEmail">
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.name}
                      <b style={{ color: "red" }}>*</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.lastName}
                      <b style={{ color: "red" }}>*</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isValid={touched.lastName && !errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label style={{ color: "#5C5C5C" }}>
                  {translate.whereYouWork}
                  <b style={{ color: "red" }}>*</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="whereYouWork"
                  value={values.whereYouWork}
                  onChange={handleChange}
                  isValid={touched.whereYouWork && !errors.whereYouWork}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ color: "#5C5C5C" }}>
                  {translate.email}
                  <b style={{ color: "red" }}>*</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ color: "#5C5C5C" }}>
                  {translate.number}
                  <b style={{ color: "red" }}>*</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="number"
                  value={values.number}
                  onChange={handleChange}
                  isValid={touched.number && !errors.number}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ color: "#5C5C5C" }}>
                  {translate.landlineNumber}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="landlineNumber"
                  value={values.landlineNumber}
                  onChange={handleChange}
                  isValid={touched.landlineNumber && !errors.landlineNumber}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ color: "#5C5C5C" }}>
                  {translate.followUp}
                </Form.Label>
                <Form.Check
                  label={translate.yes}
                  style={{ color: "#5C5C5C", marginTop: 6 }}
                  checked={completeForm_1}
                  onClick={() => setCompleteForm_1(true)}
                />
                <Form.Check
                  label={translate.no}
                  style={{ color: "#5C5C5C", marginTop: 6 }}
                  checked={!completeForm_1}
                  onClick={() => setCompleteForm_1(false)}
                />
              </Form.Group>

              {completeForm_1 ? (
                <>
                  <Form.Group>
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.nameOfPerson}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="tdNameOfPerson"
                      value={values.tdNameOfPerson}
                      onChange={handleChange}
                      isValid={touched.tdNameOfPerson && !errors.tdNameOfPerson}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.email}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="tdEmail"
                      value={values.tdEmail}
                      onChange={handleChange}
                      isValid={touched.tdEmail && !errors.tdEmail}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.trainingAdministrationNo}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="trainingAdministrationNo"
                      value={values.trainingAdministrationNo}
                      onChange={handleChange}
                      isValid={
                        touched.trainingAdministrationNo &&
                        !errors.trainingAdministrationNo
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.theOfficialMobileNumber}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="theOfficialMobileNumber"
                      value={values.theOfficialMobileNumber}
                      onChange={handleChange}
                      isValid={
                        touched.theOfficialMobileNumber &&
                        !errors.theOfficialMobileNumber
                      }
                    />
                  </Form.Group>
                </>
              ) : null}

              <Form.Group>
                <Form.Label style={{ color: "#5C5C5C" }}>
                  {translate.courseInformationTo}
                </Form.Label>
                <Form.Check
                  label={translate.yes}
                  checked={completeForm_2}
                  style={{ color: "#5C5C5C", marginTop: 6 }}
                  onClick={() => setCompleteForm_2(true)}
                />
                <Form.Check
                  label={translate.no}
                  checked={!completeForm_2}
                  style={{ color: "#5C5C5C", marginTop: 6 }}
                  onClick={() => setCompleteForm_2(false)}
                />
              </Form.Group>

              {completeForm_2 ? (
                <>
                  <Form.Group>
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.chooseAnotherDate}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="newChooseAnotherDate"
                      value={values.newChooseAnotherDate}
                      onChange={handleChange}
                      isValid={
                        touched.newChooseAnotherDate &&
                        !errors.newChooseAnotherDate
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.SuggestAnewNumberOfDays}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="newSuggestAnewNumberOfDays"
                      value={values.newSuggestAnewNumberOfDays}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.city}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="newCity"
                      value={values.newCity}
                      size="sm"
                      custom
                    >
                      {countryList.map((e, key) => {
                        return (
                          <option key={key} value={e}>
                            {e}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label style={{ color: "#5C5C5C" }}>
                      {translate.addNewCourseThemes}
                    </Form.Label>
                    <Form.Control
                      name="newCourseThemes"
                      as="textarea"
                      rows="3"
                      onChange={handleChange}
                      value={values.newCourseThemes}
                    />
                  </Form.Group>
                </>
              ) : null}

              <Form.Group>
                <Form.Label style={{ color: "#5C5C5C" }}>
                  {translate.joinOurNewsletter}
                </Form.Label>
                <Form.Check
                  name="joinOurNewsletter"
                  checked={values.joinOurNewsletter}
                  onChange={handleChange}
                  label={translate.yes}
                  style={{ color: "#5C5C5C" }}
                />
              </Form.Group>

              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  {translate.cancel}
                </Button>
                <Button
                  //type="submit"
                  disabled={!isValid}
                  variant="primary"
                  onClick={() => handleSubmit()}
                >
                  {translate.Enroll}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
