import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import { flatMap, map, uniqBy, zipObject } from "lodash"
import moment from "moment"
import React, { useState } from "react"
import CourseCard from "../components/courseCard"
import { EnrollCourseForm } from "../components/enrollCourseForm"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import "../config/firebase"
import "../css"
import "../scss/index.scss"
import locales from "../translate/locales"
import makeCourseLocationsInctance from "../utils/makeCourseLocationsInctance"

export const query = graphql`
  query($courseId: String!) {
    contentfulCourses(id: { eq: $courseId }) {
      id
      title
      language
      totalHours
      isOnline
      startDate
      endDate
      price
      introductionVideoLinkYoutubeLink
      category {
        arabic
        category
      }
      coverImage {
        file {
          url
        }
      }
      description {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }

      descriptionPdf {
        file {
          url
        }
      }
      introduction {
        json
      }
      audience {
        json
      }

      objectives {
        json
      }

      content {
        childMarkdownRemark {
          html
        }
      }
      countriesAndDates {
        location
        start_date
        end_date
        contentful_id
      }

      similarCourses {
        isOnline
        id
        startDate
        endDate

        title
        language
        totalHours
        price
        coverImage {
          file {
            url
          }
        }
        description {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }

        category {
          category
          arabic
        }

        countriesAndDates {
          location
          start_date
          contentful_id
        }
      }
    }
  }
`

const course = (props: any) => {
  const [tab, setTab] = useState("tab1")
  const [dialogShow, setDialogShow] = useState(false)

  const locale = props.pageContext.locale || "en"
  const courseInstances = flatMap(
    makeCourseLocationsInctance(props.data.contentfulCourses)
  )

  const courseData = courseInstances.find(
    (e: any) => e._navigateId === props.pageContext.navigationId
  ) as any
  const courseDate = moment(courseData.startDate).toDate().toLocaleDateString()
  const courseLang = courseData.language
  const texts = locales[locale as "en"]
  const translate = texts.coursePage
  const courseName = courseData.title
  const courseLocation = courseData.isOnline ? "online" : courseData.location
  const similarCourses = flatMap(
    props.data.contentfulCourses.similarCourses.map((x: any) => {
      return makeCourseLocationsInctance(x)
    })
  )

  const uniqSimilarCourses = uniqBy(similarCourses, function (x: any) {
    return x.id
  })

  const val =
    courseData.content?.childMarkdownRemark?.html
      ?.replace(/^\s/g, "")
      .replace(/\s$/g, "")
      .split(/<\/?h1>/)
      .map((t: any) => t.replace(/^\s/g, "@").replace(/\s$/g, ""))
      .filter((x: any) => x) ?? []

  const longText = (value: any) => {
    const result = value.childMarkdownRemark.html.split(/\<.*?\>/g)
    return result
  }

  const contents = zipObject(
    val.filter((_: any, idx: number) => idx % 2 === 0).map((x) => x?.trim()),
    val.filter((_: any, idx: number) => idx % 2 !== 0).map((x) => x?.trim())
  )

  const contentsArray = map(contents, (value, key) => {
    return {
      title: key,
      value,
    }
  })

  const otherLocationsSameCourses = courseInstances.filter(
    (e: any) => e._navigateId !== props.pageContext.navigationId
  )
  const coursesLength = otherLocationsSameCourses.length

  return (
    <div>
      <Header lang={courseData.language} />

      <section
        className="course-title-area pt-120 pb-120 bg_cover"
        style={{
          backgroundImage: `url('/images/books-book-pages-read-literature-159866.jpeg')`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="course-title-content">
                <div className="course-title-content-title">
                  <h2 className="title">{courseData.title}</h2>

                  <h5 style={{ color: "#FFF", fontWeight: 400 }}>
                    {courseData.description.childMarkdownRemark.rawMarkdownBody}
                  </h5>
                </div>

                <div className="course-info">
                  <ul>
                    <li>
                      <i className="fal fa-calendar-alt"></i> {courseDate}
                    </li>

                    <li>
                      {courseData.isOnline ? (
                        <>
                          {" "}
                          <i className="far fa-play-circle"></i>{" "}
                          {texts.header.online}{" "}
                        </>
                      ) : (
                        <>
                          <i className="fal fa-map"></i> {courseData.location}
                        </>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="course-details-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="course-details-items white-bg">
                <div className="course-thumb">
                  <img src={courseData.coverImage.file.url} alt="course" />

                  <div className="tab-btns">
                    <ul
                      className="nav nav-pills d-flex justify-content-between"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="pills-1-tab"
                          data-toggle="pill"
                          role="tab"
                          aria-controls="pills-1"
                          aria-selected="true"
                          onClick={() => setTab("tab1")}
                        >
                          <i className="fal fa-list"></i>
                          {translate.Introduction}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-2-tab"
                          data-toggle="pill"
                          onClick={() => setTab("tab2")}
                          role="tab"
                          aria-controls="pills-2"
                          aria-selected="true"
                        >
                          <i className="fal fa-book"></i> {translate.Objectives}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-3-tab"
                          data-toggle="pill"
                          onClick={() => setTab("tab3")}
                          role="tab"
                          aria-controls="pills-3"
                          aria-selected="true"
                        >
                          <i className="fal fa-user"></i> {translate.Audience}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-4-tab"
                          data-toggle="pill"
                          onClick={() => setTab("tab4")}
                          role="tab"
                          aria-controls="pills-4"
                          aria-selected="false"
                        >
                          <i className="fal fa-stars"></i> {translate.Content}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="tab-content" id="pills-tabContent">
                  {tab === "tab1" ? (
                    <div
                      className="tab-pane fade show active"
                      id="pills-1"
                      role="tabpanel"
                      aria-labelledby="pills-1-tab"
                    >
                      <div className="course-details-item">
                        <div className="course-learn-text">
                          {documentToReactComponents(
                            courseData.introduction.json
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {tab === "tab2" ? (
                    <div
                      className="tab-pane fade  fade show active"
                      id="pills-2"
                      role="tabpanel"
                      aria-labelledby="pills-2-tab"
                    >
                      <div className="course-details-item">
                        <div className="course-learn-list">
                          {courseData.objectives !== null
                            ? documentToReactComponents(
                                courseData.objectives.json
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {tab === "tab3" && (
                    <div
                      className="tab-pane fade  fade show active"
                      id="pills-2"
                      role="tabpanel"
                      aria-labelledby="pills-2-tab"
                    >
                      <div className="course-details-item">
                        <div className="course-learn-list">
                          <div className="row">
                            <div className="col-lg-12">
                              {courseData.audience !== null
                                ? documentToReactComponents(
                                    courseData.audience.json
                                  )
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {tab === "tab4" && (
                    <div
                      style={{ marginTop: 24 }}
                      className="tab-pane fade  fade show active"
                      id="pills-4"
                      role="tabpanel"
                      aria-labelledby="pills-4-tab"
                    >
                      <div className="faq-accordion mt-30">
                        <div className="accordion" id="accordionExample">
                          {map(contents, (value: string, key: string) => {
                            const k = key.replace(/\s/g, "-")
                            return (
                              <div className="card" key={key}>
                                <div
                                  className="card-header"
                                  id={`heading_key${k}`}
                                  style={{ background: "transparent" }}
                                >
                                  <a
                                    className=""
                                    href=""
                                    data-toggle="collapse"
                                    data-target={`#collapse_key${k}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse_key${k}`}
                                    style={{ color: "rgba(13, 23, 48, 0.87)" }}
                                  >
                                    {key}
                                  </a>
                                </div>

                                <div
                                  id={`collapse_key${k}`}
                                  className="collapse"
                                  aria-labelledby={`heading_key${k}`}
                                  data-parent="#accordionExample"
                                >
                                  <div className="card-body">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: value,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  <section
                    className="advance-courses-area  mentor-course pb-120 pt-105 bg_cover"
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    <h2 style={{ textAlign: "right", color: "#464646" }}>
                      الدورات المتكررة
                    </h2>
                    <div className="container">
                      <div
                        className={
                          coursesLength > 1
                            ? `row courses-active-course-page`
                            : ""
                        }
                      >
                        {(otherLocationsSameCourses ?? []).map(
                          (course: any, key: number) => {
                            return (
                              <CourseCard
                                className="col-lg-6"
                                courseData={course}
                                key={key}
                                priceStyle={{
                                  position: "absolute",
                                  bottom: 34,
                                }}
                              />
                            )
                          }
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9">
              <div className="course-details-sidebar white-bg">
                <div className="course-sidebar-thumb">
                  <img
                    src={courseData.coverImage.file.url}
                    alt="course video"
                  />
                  <a
                    className="video-popup"
                    href="https://www.youtube.com/watch?v=TdSA7gkVYU0"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                </div>
                <div className="course-sidebar-price d-flex justify-content-between align-items-center">
                  <h3 className="title">${courseData.price}</h3>
                </div>
                <div className="course-sidebar-btns">
                  <a
                    href="#"
                    className="enrollLink"
                    onClick={() => setDialogShow(true)}
                  >
                    <i className="fal fa-shopping-cart"></i>
                    {translate.enrollNow}
                  </a>

                  <h6 className="title">{translate.ThisCourseIncludes}</h6>
                  <ul>
                    {courseData.isOnline ? (
                      <li>
                        <i className="far fa-play-circle"></i>{" "}
                        {texts.header.online}
                      </li>
                    ) : null}

                    <li>
                      <i className="fal fa-clock"></i>
                      {courseData.totalHours} {translate.hour}
                    </li>

                    <li>
                      <i className="fal fa-calendar-alt"></i>
                      {courseData.days} {translate.day}
                    </li>

                    {courseData.coursePdfFile !== null ? (
                      <li
                        onClick={() => {
                          window.open(courseData.coursePdfFile.file.url)
                        }}
                      >
                        <i className="far fa-file-pdf"></i>
                        <span className="downloadFileLi">
                          {translate.downloadPdf}
                        </span>
                      </li>
                    ) : null}

                    {courseData.calendarFile !== null ? (
                      <li
                        onClick={() => {
                          window.open(courseData.calendarFile.file.url)
                        }}
                      >
                        <i className="fas fa-calendar-week"></i>
                        <span className="downloadFileLi">
                          {translate.downloadCalendar}
                        </span>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <div className="trending-course">
                <h4 className="title" style={{ paddingBottom: 35 }}>
                  <i className="fal fa-book"></i>
                  {translate.similarCourse}
                </h4>
                <section
                  className="advance-courses-area advance-courses-area-2 mentor-course pb-120 pt-105 bg_cover"
                  style={{
                    backgroundColor: "#fff",
                    padding: 0,
                    paddingBottom: 80,
                  }}
                >
                  <div className="container">
                    <div className="row courses-active-course-page-similar ">
                      {(uniqSimilarCourses ?? []).map(
                        (course: any, key: number) => {
                          return (
                            <CourseCard
                              courseData={course}
                              key={key}
                              className={
                                uniqSimilarCourses.length === 1
                                  ? "col-md-12"
                                  : undefined
                              }
                              priceStyle={{ position: "absolute", bottom: 34 }}
                            />
                          )
                        }
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>

      <EnrollCourseForm
        courseId=""
        setShowModal={setDialogShow}
        showModal={dialogShow}
        lang={locale}
        courseName={courseName}
        courseLocation={courseLocation}
      />

      <Footer lang={courseLang} />
    </div>
  )
}

export default course
